import { bindable, inject }  from 'aurelia-framework';
import { I18N }              from 'aurelia-i18n';
import { BaseListViewModel } from 'base-list-view-model';
import { FilterFormSchema }  from 'modules/management/bituminous-mixtures/layers/filter-form-schema';
import { AppContainer }      from 'resources/services/app-container';
import { Downloader }        from 'resources/services/downloader';
import { LayersRepository }  from './services/repository';

@inject(AppContainer, LayersRepository, I18N, Downloader, FilterFormSchema)
export class ListLayers extends BaseListViewModel {

    listingId = 'management-layers-listing';

    @bindable headerTitle    = 'listing.management.bituminous-mixtures.layers';
    @bindable newRecordRoute = 'management.bituminous-mixtures.layers.create';
    @bindable repository;
    @bindable datatable;

    canEdit = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.canEdit = this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.layers.manage', 'management.bituminous-mixtures.layers.edit']);

        await this.loadFullData();
        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Loads the full dataset
     */
    async loadFullData() {
        const response = await this.repository.search({});
        this.fullData  = response.data;
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            dblClick:        !this.canEdit,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.bituminous-mixtures.layers.view', { id: row.id }),
                visible: () => this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.layers.manage', 'management.bituminous-mixtures.layers.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.bituminous-mixtures.layers.edit', { id: row.id }),
                visible: () => this.canEdit,
            },
            destroy:         {
                visible: () => this.appContainer.authenticatedUser.can(['management.bituminous-mixtures.layers.manage', 'management.bituminous-mixtures.layers.delete']),
            },
            options:         [],
            buttons:         [
                {
                    label:            'form.button.save-order',
                    icon:             'icon-list-ordered',
                    visible:          this.canEdit,
                    action:           async () => await this.updateOrder(),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      true,
            destroySelected: true,
            destroyed:       async () => {
                await this.loadFullData();
                await this.updateOrder();
                this.datatable.instance.reload();
            },
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:   'order',
                    name:   'bm_layers.order',
                    title:  'form.field.order',
                    center: this.canEdit,
                    type:   this.canEdit ? 'input' : null,
                    number: true,
                },
                {
                    data:  'name',
                    name:  'bm_layer_translations.name',
                    title: 'form.field.name',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'bm_layers.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Updates the order of the records
     */
    async updateOrder() {
        const datatableData = this.datatable.instance.data;

        await this.fullData.forEach(row => {
            const datatableRow = datatableData.find(datatableRow => datatableRow.id === row.id);
            if (datatableRow) {
                row.order = Number(datatableRow.order);
            }
        });

        const response = await this.repository.updateOrder(this.fullData);

        if (response.status !== true) {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        this.datatable.instance.reload();
    }

    /**
     * Searches for records given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        // You can update this function to ensure it works well with pagination if needed
        return this.repository.search(criteria);
    }
}
